import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/core/services/common.service";
import { StoreService } from "src/app/core/services/store.service";
import { UserService } from "src/app/core/services/user.service";
import { EnduserService } from "src/app/core/services/enduser.service";
// import * as braintreeWeb from 'braintree-web';
declare var $: any;
import Swal from "sweetalert2";
import { SharedService } from "src/app/core/services/shared.service";
import { environment } from "src/environments/environment";
import { CouponDetailsService } from "src/app/core/services/coupon-details.service";
import { MatDialog } from "@angular/material/dialog";
import { ResponseWindowComponent } from "../response-window/response-window.component";
const bDropIn = require('braintree-web-drop-in')
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: [
    "./checkout.component.scss",
  ],
})
export class CheckoutComponent implements OnInit {

  loading = false;
  addCoupon: UntypedFormControl = new UntypedFormControl('');
  invalidCouponMessage = ''
  teamStoreId;
  cartItems: any;
  paymentMethod: number = 3;
  user: any;
  totalAmount: number = 0;
  totalNumberOfProducts: number = 0;
  currentDate = new Date().toLocaleDateString();
  teamStore: any;
  updatedBillingAddress: UntypedFormGroup;
  updatedShippingAddress: UntypedFormGroup;
  updatedCustomerInformation: UntypedFormGroup;
  isShippingAddressSameAsBillingAddress = false;
  organizationArray: any;
  selectedOrganization: any;
  checkoutObj: any;
  states: any[] = [];
  customerInfoValidity = {
    name: true,
    phone: true,
    all: true,
  }
  invalidShippingAddress = false
  invalidBillingAddress = false
  invalidShippingMethod = false
  shippingMethods: any = [];
  freeShipping = {
    id: 3,
    name: "Free shipping",
    price: 0,
  };
  uploadedPO: any;
  selectedShippingMethod: any = {};
  creditApprovalCode: string;
  billingAddress;
  shippingAddress;
  purchaseType: number;
  serviceArray = [];
  cardInfo: UntypedFormGroup;
  cardExpYears = [];
  availableCards = {
    AE: { prefix: [34, 37], length: 15 },
    V: { prefix: [4], length: 16 },
    MC: { prefix: [51, 52, 53, 54, 55], length: 16 },
    D: { prefix: [6011, 5], length: 16 },
    DC: { prefix: [300, 301, 302, 303, 304, 305, 36, 38], length: 14 },
    JCB: { prefix: [2131, 1800, 35], length: 16 },
  };
  cardLogos = [
    "../../../../../assets/images/visa.svg",
    "../../../../../assets/images/mastercard.jpg",
    "../../../../../assets/images/american_express.png",
    "../../../../../assets/images/discover.jpg",
  ]
  personalizationTypes = [
    "(No personalization)",
    "(Name)",
    "(Number)",
    "(Name, Number)",
    "(Not available)",
  ];
  taxObject;
  errors = {
    cardNumber: false,
    cardExpiryMonth: false,
    cardExpiryYear: false,
    cardCvv: false,
    cardName: false,
  }
  saveCardToVault = false
  public phonePattern = "[0-9]{10}";
  showSavedCards = false
  selectedCard
  payWithNewCard = true
  savedCards = []
  clientToken
  discountAmount: any;
  subtotalPrice: any;
  offerApplied = false;
  showButton = false;
  couponDiscountValue: any;
  defaultSelection='';
  formData: any;
  form
  orderCustomFields: any[] = [];
  isShippingChargeLoading: boolean=true;
  isTaxLoadeding: boolean=true;
  couponAppliedList: any;
  shippingAfterCouponApplied: any;
  CouponApplyShippingMethodId: any;
  rotateArrow: boolean[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private commonService: CommonService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private endUserService: EnduserService,
    private sharedService: SharedService,
    private couponService: CouponDetailsService,
    private dialogue: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private fbr: UntypedFormBuilder
  ) {
    if (this.checkoutObj && this.checkoutObj.cartItemsInfo) {
      this.rotateArrow = new Array(this.checkoutObj.cartItemsInfo.length).fill(false);
    }
   }

  ngOnInit() {
    this.getCartList()
    // to be moved to get cards function
    this.scrollToTop()
    this.getClientToken()
    window.parent.postMessage("update title-Checkout", "*")
    this.teamStoreId = Number(localStorage.getItem("teamStoreId"));
    this.checkoutObj = JSON.parse(localStorage.getItem("checkoutObj"));

    this.purchaseType = JSON.parse(localStorage.getItem("purchaseType"));
    this.resetAddress();
    this.getUserDetails();
    this.getTeamStore();
    this.createAddressForm();
    this.getOrganizations();
    this.getStates();
    this.getAvailableStoreServices();
    this.getShippingMethods();
    setInterval(() => {
      this.commonService.postHeightToParent("low")
    }, 1000);
    this.form = this.fbr.group({});
    this.form.valueChanges.subscribe((formValue) => {
      this.saveFormValues(formValue);
    });
  }
  getCartList() {
    this.commonService.toggleLoading(true)  
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    if (userId && token) {
      this.storeService.getCartList().subscribe(response => {
        this.cartItems = response.data; 
        this.checkoutObj.shippingBoxes=this.cartItems.shippingBoxes;
        this.commonService.toggleLoading(false)  
      },
        error => {
          this.cartItems = {}; 
          this.checkoutObj.shippingBoxes=null;
          if (error.status == 401) { 
          }
          this.commonService.toggleLoading(false)    
        });
    } else { 
      this.commonService.toggleLoading(false)   
    }
  }
  saveFormValues(formValue: any) {
    this.orderCustomFields = this.formData.map((controlData) => ({
      TeamStoreCustomFieldId: controlData.id,
      value: formValue[controlData.fieldLabel],
    }));
  }
  getSavedCards() {
    // get cards function here
    this.savedCards = [
      {
        id: 1,
        cardType: 'Visa',
        lastDigits: '1234',
        cardHolderName: 'Ken Adams',
        cardExpiryMonth: '09',
        cardExpiryYear: '2026',
      },
      {
        id: 2,
        cardType: 'Master Card',
        lastDigits: '1769',
        cardHolderName: 'Regina Filan',
        cardExpiryMonth: '09',
        cardExpiryYear: '2025',
        isDefault: true
      },
      {
        id: 1,
        cardType: 'Visa',
        lastDigits: '1234',
        cardHolderName: 'Ken Adams',
        cardExpiryMonth: '09',
        cardExpiryYear: '2026',
      },
      {
        id: 2,
        cardType: 'Discover',
        lastDigits: '1769',
        cardHolderName: 'Regina Filan',
        cardExpiryMonth: '09',
        cardExpiryYear: '2025',
      },
      {
        id: 1,
        cardType: 'Visa',
        lastDigits: '1234',
        cardHolderName: 'Ken Adams',
        cardExpiryMonth: '09',
        cardExpiryYear: '2026',
      },
      {
        id: 2,
        cardType: 'Master Card',
        lastDigits: '1769',
        cardHolderName: 'Regina Filan',
        cardExpiryMonth: '09',
        cardExpiryYear: '2025',
      },
      {
        id: 1,
        cardType: 'American Express',
        lastDigits: '1234',
        cardHolderName: 'Ken Adams',
        cardExpiryMonth: '09',
        cardExpiryYear: '2026',
      },
      {
        id: 2,
        cardType: 'Master Card',
        lastDigits: '1769',
        cardHolderName: 'Regina Filan',
        cardExpiryMonth: '09',
        cardExpiryYear: '2025',
      },
      {
        id: 1,
        cardType: 'Some Other Card',
        lastDigits: '1234',
        cardHolderName: 'Ken Adams',
        cardExpiryMonth: '09',
        cardExpiryYear: '2026',
      },
    ]
    if (this.savedCards.length) this.choosePaymentMethod()
  }

  createBrainTreeDropInUI() {
    this.disableButtons()
    var _this = this
    bDropIn.create({
      container: '#dropin-container',
      authorization:
        this.clientToken ||
        environment.braintree.tokenizationKey
      ,
      card: {
        cardholderName: {
          required: true
        },
        vault: {
          allowVaultCardOverride: true
        },
        overrides: {
          fields: {
            postalCode: null
          }
        },
      },
      paypal: {
        flow: 'vault',
        vault: {
          vaultPayPal: true
        },
      },
      // venmo: {
      //   allowNewBrowserTab: false
      // },
    }, function (createErr, instance) {
      if (createErr) return
      _this.enableButtons()
      var submitButton = $('#payment-request-button')
      submitButton.click(function (event) {
        instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
          if (requestPaymentMethodErr) {
            console.log(requestPaymentMethodErr);
            _this.braintreeAuthenticationFailed()
            return
          } else {
            let cardInfo = {
              "nameOnCard": payload.details.cardholderName,
              "paymentMethodNonce": payload.nonce
            }
            _this.payByCreditCard(cardInfo)
          }
        });
      });
    })
  }

  braintreeAuthenticationFailed() {
    this.commonService.toggleLoading(false)
    this.enableButtons()
  }

  disableButtons() {
    $('#back-to-cart-btn').prop('disabled', true)
    $('#pay-now-btn').prop('disabled', true)
  }

  enableButtons() {
    $('#back-to-cart-btn').prop('disabled', false)
    $('#pay-now-btn').prop('disabled', false)
  }

  getClientToken() {
    this.userService.getBrainTreeClientToken().subscribe((res: any) => {
      this.clientToken = res.data
      this.createBrainTreeDropInUI()
    }, err => {
      this.createBrainTreeDropInUI()
    })
  }

  choosePaymentMethod() {
    this.showSavedCards = true
    this.selectedCard = null
  }

  useNewCard() {
    // this.createBraintreeUI(true)
    this.showSavedCards = false
  }

  selectSavedCard(card) {
    this.selectedCard = card
    this.showSavedCards = false
    // this.createBraintreeUI(false)
  }

  getShippingMethods() {
    if (this.purchaseType == 3) return;
    this.isShippingChargeLoading=true
    this.sharedService.getShippingMethods().subscribe((res: any) => {
      this.shippingMethods = res.data;
    this.isShippingChargeLoading=false

    });
  }

  getShippingCharge() {
    if (this.teamStoreId) return;
    this.loading = true;
    let data = {
      shipTo: {
        zipCode: this.shippingAddress.zipCode,
        country: "US",
      },
      packages: this.checkoutObj.shippingBoxes,
      totalWeight: this.checkoutObj.totalWeight,
    };
    this.isShippingChargeLoading=true
    this.sharedService
      .getShippingCharge(this.selectedShippingMethod.code, data)
      .subscribe(
        (res) => {
          this.selectedShippingMethod["price"] = Number(
            res.data.totalCharges.monetaryValue
          );
          this.loading = false;
          this.isShippingChargeLoading=false
          this.getTax()
        },
        (err) => {
          this.loading = false;
          this.isShippingChargeLoading=true
        }
      );
  }

  getTeamStore() {
    if (!this.teamStoreId) return;
    this.storeService.getStore(this.teamStoreId).subscribe((response) => {
      this.teamStore = response.data;
      this.setAddress();
      this.shippingMethods = this.teamStore.availableShippingMethods;
      this.selectShippingMethod(this.shippingMethods[0].id)
      // if (this.teamStore && this.teamStore.shippingPreference === 2) {
      //   this.selectedShippingMethod = this.freeShipping
      // }
       this.formData=this.teamStore?.customFields
       if(this.formData.length>0){
        this.formData.forEach(controlData => {
        const validators = controlData.isMandatory ? [Validators.required] : [];
        this.form.addControl(
          controlData.fieldLabel,
          this.fb.control('', validators)
        );
        });
       }
       
    });
  }
  onSubmit() {
    // Handle form submission logic here
    if (this.form.valid) {
      // Form is valid, proceed with submission
      console.log(this.form.value);
    } else {
      // Form is invalid, display error messages
      // You can access error messages with this.form.get('controlName').errors
    }
  }

  getUserDetails() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    this.userService.getProfileDetails(userId, token).subscribe((response) => {
      this.user = response.data;
      // this.updateShippingAddressAsBillingAddress(this.user.isSameAsBillingAddress)
      this.isShippingAddressSameAsBillingAddress =
        this.user.isSameAsBillingAddress;
      this.setAddress();
      this.getSavedCards()
      // this.commonService.postHeightToParent("medium")
    });
  }

  updateShippingAddressAsBillingAddress(state) {
    // this.isShippingAddressSameAsBillingAddress = state;
    if (!this.billingAddress.billingAddress)
      if (state) {
        this.shippingAddress = { ...this.billingAddress };
        this.shippingAddress["isEditable"] = false;
      } else {
        this.shippingAddress = {
          address: this.user.shippingAddress,
          address1: this.user.shippingAddress1,
          city: this.user.shippingCity,
          state:
            this.getState(this.user.shippingState).name ||
            this.user.shippingState,
          zipCode: this.user.shippingZipCode,
          isEditable: true,
        };
      }
    this.validateAddress();
    if (this.purchaseType != 3){
      this.getShippingCharge()
    }
    this.getTax() 
  }

  createAddressForm() {
    let updateProfile = this.user && this.user.emailConfirmed ? false : true
    this.updatedBillingAddress = this.fb.group({
      billingAddress: new UntypedFormControl(this.billingAddress.address || ""),
      billingAddress1: new UntypedFormControl(this.billingAddress.address1 || ""),
      billingCity: new UntypedFormControl(this.billingAddress.city || ""),
      billingState: new UntypedFormControl(
        this.getState(this.billingAddress.state).name ||
        this.billingAddress.state,
        Validators.min(0)
      ),
      billingZipCode: new UntypedFormControl(this.billingAddress.zipCode || ""),
      updateProfile: new UntypedFormControl(updateProfile),
    });
    this.updatedShippingAddress = this.fb.group({
      shippingAddress: new UntypedFormControl(this.shippingAddress.address || ""),
      shippingAddress1: new UntypedFormControl(this.shippingAddress.address1 || ""),
      shippingCity: new UntypedFormControl(this.shippingAddress.city || ""),
      shippingState: new UntypedFormControl(
        this.getState(this.shippingAddress.state).name ||
        this.shippingAddress.state,
        Validators.min(0)
      ),
      shippingZipCode: new UntypedFormControl(this.shippingAddress.zipCode || ""),
      updateProfile: new UntypedFormControl(updateProfile),
    });
    let user = this.user || {}
    this.updatedCustomerInformation = this.fb.group({
      email: (user.email || ''),
      firstName: [(user.firstName || ''), Validators.required],
      lastName: [(user.lastName || ''), Validators.required],
      phoneNumber: [(user.phoneNumber || ''), Validators.pattern(this.phonePattern)],
    })
  }

  updateBillingAddress() {
    let form = document.getElementById(
      "updatedBillingAddressForm"
    ) as HTMLFormElement;
    if (this.updatedBillingAddress.get("billingState").value == -1) {
      this.updatedBillingAddress.get("billingState").setValue(null);
    }
    if (form.checkValidity()) {
      this.user.billingAddress =
        this.updatedBillingAddress.get("billingAddress").value;
      this.user.billingAddress1 =
        this.updatedBillingAddress.get("billingAddress1").value;
      this.user.billingCity =
        this.updatedBillingAddress.get("billingCity").value;
      this.user.billingState =
        this.updatedBillingAddress.get("billingState").value;
      this.user.billingZipCode =
        this.updatedBillingAddress.get("billingZipCode").value;

      this.user.isSameAsBillingAddress =
        this.isShippingAddressSameAsBillingAddress;

      if (this.isShippingAddressSameAsBillingAddress) {
        this.user.shippingAddress =
          this.updatedBillingAddress.get("billingAddress").value;
        this.user.shippingAddress1 =
          this.updatedBillingAddress.get("billingAddress1").value;
        this.user.shippingCity =
          this.updatedBillingAddress.get("billingCity").value;
        this.user.shippingState =
          this.updatedBillingAddress.get("billingState").value;
        this.user.shippingZipCode =
          this.updatedBillingAddress.get("billingZipCode").value;
        this.user.isSameAsBillingAddress = true;
      }
      if (this.updatedBillingAddress.get("updateProfile").value) {
        this.updateUserProfile();
      }
      this.setAddress();
      this.closeModal();
      this.invalidBillingAddress = false
    } else {
      this.commonService.openErrorSnackBar("Fill all required fields", "");
      form.classList.add("was-validated");
    }
  }

  updateShippingAddress() {
    let form = document.getElementById(
      "updatedShippingAddressForm"
    ) as HTMLFormElement;
    if (this.updatedShippingAddress.get("shippingState").value == -1) {
      this.updatedShippingAddress.get("shippingState").setValue(null);
    }
    if (form.checkValidity()) {
      this.user.shippingAddress =
        this.updatedShippingAddress.get("shippingAddress").value;
      this.user.shippingAddress1 =
        this.updatedShippingAddress.get("shippingAddress1").value;
      this.user.shippingCity =
        this.updatedShippingAddress.get("shippingCity").value;
      this.user.shippingState =
        this.updatedShippingAddress.get("shippingState").value;
      this.user.shippingZipCode =
        this.updatedShippingAddress.get("shippingZipCode").value;
      this.user.isSameAsBillingAddress =
        this.isShippingAddressSameAsBillingAddress;
      if (this.updatedShippingAddress.get("updateProfile").value) {
        this.updateUserProfile();
      }
      this.setAddress()
      this.closeModal();
      this.validateAddress();
      if (this.purchaseType != 3){
      this.getShippingCharge()
      }
      this.getTax() 
      this.invalidShippingAddress = false
    } else {
      this.commonService.openErrorSnackBar("Fill all values", "");
      form.classList.add("was-validated");
    }
  }

  updateCustomerInfo() {
    let form = document.getElementById(
      "updatedCustomerInformationForm"
    ) as HTMLFormElement;
    if (this.updatedCustomerInformation.valid) {
      form.classList.add("was-validated");
      this.user.firstName = this.updatedCustomerInformation.value.firstName
      this.user.lastName = this.updatedCustomerInformation.value.lastName
      this.user.phoneNumber = this.updatedCustomerInformation.value.phoneNumber
      this.updateUserProfile();
      this.closeModal()
      this.validateCustomerInfo(false)
      let usrFrmStorage = JSON.parse(localStorage.getItem('user'))
      usrFrmStorage.name = this.user.firstName + " " + this.user.lastName
      usrFrmStorage.firstName = this.user.firstName
      usrFrmStorage.lastName = this.user.lastName
      usrFrmStorage.phoneNumber = this.user.phoneNumber
      localStorage.setItem("user", JSON.stringify(usrFrmStorage))
      window.parent.postMessage({
        user: usrFrmStorage,
        token: localStorage.getItem('token'),
        cartTotal: Number(JSON.parse(localStorage.getItem('checkoutObj')).cartCount),
        isUserVerified: false,
        refreshToken: localStorage.getItem('refreshToken'),
      }, "*");
    } else {
      this.commonService.openErrorSnackBar("Fill all values", "");
      this.updatedCustomerInformation.markAllAsTouched()
    }
  }

  updateUserProfile() {
    const token = localStorage.getItem("token");
    this.userService
      .updateProfileDetails(this.user, token)
      .subscribe((response: any) => {
        if (response.status == 1) {
          this.commonService.openSuccessSnackBar("User profile updated", "");
        }
      });
  }

  closeModal() {
    $("#customerInfoModal").modal("hide");
    $("#billingAddressModal").modal("hide");
    $("#shippingAddressModal").modal("hide");
    $("#customerInfoModal").removeClass("was-validated");
    $("#updatedShippingAddressForm").removeClass("was-validated");
    $("#updatedBillingAddressForm").removeClass("was-validated");
    this.createAddressForm();
  }

  getOrganizations() {
    const params = { isApproved: true };
    this.userService.getCreditOrganizations(params).subscribe((response) => {
      this.organizationArray = response.data;
    });
  }

  selectOrganization(id = null) {
    this.selectedOrganization = this.organizationArray.find((el) => {
      return el.id == id;
    });
    this.setAddress();
  }

  selectShippingMethod(id) {
    this.isShippingChargeLoading=true
    this.selectedShippingMethod=null
    this.taxObject=null;
    this.selectedShippingMethod = this.shippingMethods.find((el) => {
      return el.id == id;
    });
    this.defaultSelection=this.selectedShippingMethod?.id?this.selectedShippingMethod?.id:'-- Select Shipping Method --'
     
    this.invalidShippingMethod = false
    // if (!this.selectedShippingMethod?.price)
     this.getShippingCharge();
  }

  setAddress() {
    // teamstore purchase
    if (this.purchaseType == 3) {
      // shipping preference = individual
      if (this.teamStore && this.teamStore.shippingPreference === 1) {
        // payment method = credit
        if (this.paymentMethod === 3) {
          this.resetAddress(true, true, true);
          if (this.user) {
            this.billingAddress = {
              address: this.user.billingAddress,
              address1: this.user.billingAddress1,
              city: this.user.billingCity,
              state:
                this.getState(this.user.billingState).name ||
                this.user.billingState,
              zipCode: this.user.billingZipCode,
              isEditable: true,
            };
            if (this.user.isSameAsBillingAddress) {
              this.shippingAddress = { ...this.billingAddress };
              this.shippingAddress.isEditable = false;
            } else {
              this.shippingAddress = {
                address: this.user.shippingAddress,
                address1: this.user.shippingAddress1,
                city: this.user.shippingCity,
                state:
                  this.getState(this.user.shippingState).name ||
                  this.user.shippingState,
                zipCode: this.user.shippingZipCode,
                isEditable: true,
              };
            }
          }

          // payment method = invoice
        } else if (this.paymentMethod === 1 || this.paymentMethod === 2) {
          this.resetAddress(true, true, false);
          if (this.selectedOrganization) {
            this.billingAddress = {
              orgName: this.selectedOrganization.name,
              address: this.selectedOrganization.address || "",
              address1: this.selectedOrganization.address1 || "",
              city: this.selectedOrganization.city || "",
              state:
                this.getState(this.selectedOrganization.state).name ||
                this.selectedOrganization.state,
              zipCode: this.selectedOrganization.zip || "",
              isEditable: false,
            };
          }
          if (this.user) {
            this.shippingAddress = {
              address: this.user.shippingAddress,
              address1: this.user.shippingAddress1,
              city: this.user.shippingCity,
              state:
                this.getState(this.user.shippingState).name ||
                this.user.shippingState,
              zipCode: this.user.shippingZipCode,
              isEditable: true,
            };
          }
        }
      }
      // shipping freference = bulk
      else if (this.teamStore) {
        // && this.teamStore.shippingPreference === 2
        // shipping address is always shipTo address of store
        let addArr = this.teamStore.shipToAddress.split(',')
        let state = this.getState(this.teamStore.shipToState)
        this.shippingAddress = {
          address: this.teamStore.shipToAddress,
          address1: this.teamStore.shipToAddress1,
          city: this.teamStore.shipToCity,
          state: state.name,
          zipCode: this.teamStore.shipToZipCode,
          isEditable: false,
        };
        // payment method = credit
        if (this.paymentMethod === 3) {
          this.resetAddress(true, false, true);
          if (this.user) {
            this.billingAddress = {
              address: this.user.billingAddress,
              address1: this.user.billingAddress1,
              city: this.user.billingCity,
              state:
                this.getState(this.user.billingState).name ||
                this.user.billingState,
              zipCode: this.user.billingZipCode,
              isEditable: true,
            };
          }
          //payment method = invoice
        } else if (this.paymentMethod === 1 || this.paymentMethod === 2) {
          this.resetAddress(true, false, false);
          if (this.selectedOrganization) {
            this.billingAddress = {
              orgName: this.selectedOrganization.name,
              address: this.selectedOrganization.address || "",
              address1: this.selectedOrganization.address1 || "",
              city: this.selectedOrganization.city || "",
              state:
                this.getState(this.selectedOrganization.state).name ||
                this.selectedOrganization.state,
              zipCode: this.selectedOrganization.zip || "",
              isEditable: false,
            };
          }
        }
      } else if (this.user) {
        this.billingAddress = {
          address: this.user.billingAddress,
          address1: this.user.billingAddress1,
          city: this.user.billingCity,
          state:
            this.getState(this.user.billingState).name ||
            this.user.billingState,
          zipCode: this.user.billingZipCode,
          isEditable: true,
        };
        if (this.user.isSameAsBillingAddress) {
          this.shippingAddress = { ...this.billingAddress };
          this.shippingAddress.isEditable = false;
        } else {
          this.shippingAddress = {
            address: this.user.shippingAddress,
            address1: this.user.shippingAddress1,
            city: this.user.shippingCity,
            state:
              this.getState(this.user.shippingState).name ||
              this.user.shippingState,
            zipCode: this.user.shippingZipCode,
            isEditable: true,
          };
        }
      }
      // direct purchase
    } else {
      if (this.paymentMethod === 1 || this.paymentMethod === 2) {
        this.resetAddress(true, true, false);
        if (this.selectedOrganization) {
          this.billingAddress = {
            orgName: this.selectedOrganization.name,
            address: this.selectedOrganization.address || "",
            address1: this.selectedOrganization.address1 || "",
            city: this.selectedOrganization.city || "",
            state:
              this.getState(this.selectedOrganization.state).name ||
              this.selectedOrganization.state,
            zipCode: this.selectedOrganization.zip || "",
            isEditable: false,
          };
          this.shippingAddress = {
            orgName: this.selectedOrganization.name,
            address: this.selectedOrganization.address || "",
            address1: this.selectedOrganization.address1 || "",
            city: this.selectedOrganization.city || "",
            state:
              this.getState(this.selectedOrganization.state).name ||
              this.selectedOrganization.state,
            zipCode: this.selectedOrganization.zip || "",
            isEditable: false,
          };
        }
      } else if (this.paymentMethod == 3) {
        this.resetAddress(true, true, true);
        if (this.user) {
          this.billingAddress = {
            address: this.user.billingAddress,
            address1: this.user.billingAddress1,
            city: this.user.billingCity,
            state:
              this.getState(this.user.billingState).name ||
              this.user.billingState,
            zipCode: this.user.billingZipCode,
            isEditable: true,
          };
          if (this.user.isSameAsBillingAddress) {
            this.shippingAddress = { ...this.billingAddress };
            this.shippingAddress.isEditable = false;
          } else {
            this.shippingAddress = {
              address: this.user.shippingAddress,
              address1: this.user.shippingAddress1,
              city: this.user.shippingCity,
              state:
                this.getState(this.user.shippingState).name ||
                this.user.shippingState,
              zipCode: this.user.shippingZipCode,
              isEditable: true,
            };
          }
        }
      }
    }
    setTimeout(() => {
      this.getTax()
  }, 1000);
    this.createAddressForm();
    this.validateAddress();
    this.validateAll();
  }

  discountCoupon() {
    if(this.selectedShippingMethod?.id){
    this.invalidCouponMessage = ''
    this.addCoupon.setErrors(null)
    if (!this.addCoupon.value) return
    let data = {
      Code: this.addCoupon.value,
      TotalAmount: this.checkoutObj.totalPrice,
      TeamStoreId: this.teamStoreId,
      ShippingAmount:this.selectedShippingMethod?.price,
      ShippingMethodId:this.selectedShippingMethod?.id || null
    }

    this.couponService.applyCoupon(data).subscribe(res => {
      if (res.status != 0) {
        this.showButton = true
        this.offerApplied = true
        this.discountAmount = res.data.couponDiscountValue
        this.subtotalPrice = res.data.totalAmountDeducted
        this.couponDiscountValue = res.data.couponDiscountValue
        this.couponAppliedList=res?.data?.couponApplyResponseDto?.productForCouponDtos || null
        this.shippingAfterCouponApplied=res?.data?.couponApplyResponseDto?.shippingAmountAfterDiscount
        this.CouponApplyShippingMethodId=res?.data?.couponApplyResponseDto?.shippingMethodId
        if(res?.data?.couponApplyResponseDto?.erroormsg?.length>0){
          res?.data?.couponApplyResponseDto?.erroormsg.forEach((x, index) => {
            setTimeout(() => {
              this.commonService.openErrorSnackBar(x, "");
            }, index * 1000);  
          });
        }
        setTimeout(() => {
          if (this.purchaseType != 3){
            this.getShippingCharge()
          }
         else{this.getTax()}
       }, res?.data?.couponApplyResponseDto?.erroormsg?.length * 1000);
        this.commonService.openSuccessSnackBar(res.message, "")
      } else {
        this.addCoupon.setErrors({ invalid: true })
        this.invalidCouponMessage = res.message
        this.commonService.openErrorSnackBar(res.message, "")
      }
    })}else{
      this.commonService.openErrorSnackBar('Select a shipping method !', "")      
    }
  }
  isStrikethroughSubForDiscountAmount(product) {
    // let res= this.couponAppliedList?.filter(item => item.colorId===product.colorId && item.sizeId===product.sizeId && Number(item.quantity)===Number(product.quantity) && item.totalPrice===product.totalPrice);
    // let res=this.couponAppliedList?.filter(item=> item.id==product.id);
    // return res[0]?.discountappliedprize?.toFixed(2) || 0;
    let res = this.couponAppliedList?.filter(item => item.id == product.id);
    const discountPrice = res[0]?.discountappliedprize;
    if (typeof discountPrice === 'number') {
      return discountPrice.toFixed(2);
    } else {
      return 0;
    }
  }
  isStrikethroughSub(product): boolean {
    // return this.couponAppliedList?.some(item => item.colorId===product.colorId && item.sizeId===product.sizeId && Number(item.quantity)===Number(product.quantity) && item.totalPrice===product.totalPrice);
    return this.couponAppliedList?.some(item=> item.id==product.id);
  }

  isStrikethrough(product): boolean {
    return this.couponAppliedList?.some(item => item.shoppingCartId==product.cartId );

    // return this.couponAppliedList?.some(item => item.shoppingCartId==product.cartId && item.productId === product.productId  && item.colorId===product.colorId && item.service_ID===product.serviceId );
  }
  getDiscountedPrice(item: any): number {
    const couponItem = this.couponAppliedList.find(coupon =>
        coupon.shoppingCartId===item.cartId &&
        coupon.productId === item.productId &&
        coupon.colorId === item.colorId &&
        coupon.service_ID === item.serviceId  
    );
    return couponItem ? couponItem.discountappliedprize : 0;
    let sum=0
    item?.cartItemGrids.forEach(x=>{
      if(this.isStrikethroughSubForDiscountAmount(x))
      sum+=Number(this.isStrikethroughSubForDiscountAmount(x))
    }) 
    return sum;
}
  restCoupon() {
    this.offerApplied = false
    this.addCoupon.setValue('')
    this.couponAppliedList=null;
    this.shippingAfterCouponApplied=null
    this.CouponApplyShippingMethodId=null
    if (this.purchaseType != 3){
      this.getShippingCharge()
    }
   else{this.getTax()}
  }
  getShipAmount(){
    let shpAmount;
      if (this.shippingAfterCouponApplied !== undefined && this.shippingAfterCouponApplied !== null && this.selectedShippingMethod?.id==this.CouponApplyShippingMethodId) {
         shpAmount = Number(this.shippingAfterCouponApplied);
      } else {
          shpAmount = Number(this.selectedShippingMethod?.price || 0);
      }
      return shpAmount
  }
  getTax() {
    this.isTaxLoadeding=true;
    let stateCode = "";
    let state = this.states.find(
      (state) => Number(state.name) == this.shippingAddress.state
    );
    stateCode = state ? state.code : "";
    let quantity=0
    this.checkoutObj?.cartItemsInfo.forEach((element) => {
      quantity=quantity+element.quantity
    });
    if (
      this.shippingAddress.state &&
      this.shippingAddress.zipCode &&
      this.checkoutObj.totalPrice &&  
      quantity
    ) {
      let shpAmount;
      if (this.shippingAfterCouponApplied !== undefined && this.shippingAfterCouponApplied !== null && this.selectedShippingMethod?.id==this.CouponApplyShippingMethodId) {
            shpAmount = Number(this.shippingAfterCouponApplied);
      } else {
            shpAmount = Number(this.selectedShippingMethod?.price || 0);
      }

      let amount=this.offerApplied ? Number(this.subtotalPrice)+(shpAmount ) : Number(this.checkoutObj.totalPrice)+(Number(this.selectedShippingMethod?.price||0))
      this.sharedService
        .getTax({
          // "Address": "920 W Main St",
          // "City": "Ohio",
          State:
            this.getState(this.shippingAddress.state).name ||
            this.shippingAddress.state,
          ZipCode: this.shippingAddress.zipCode,
          // "Country": "US",
          Amount: amount,
          Quantity:quantity
        })
        .subscribe((res) => {
          this.taxObject = res.data;
          if(res.statusCode==200){
            this.isTaxLoadeding=false;
          }else{
            this.isTaxLoadeding=true; 
          }
        });
    }
  }

  resetAddress(
    billing: boolean = true,
    shipping: boolean = true,
    isEditable: boolean = true
  ) {
    if (billing) {
      this.billingAddress = {
        address: "",
        address1: "",
        city: "",
        state: "",
        zipCode: "",
        isEditable,
      };
    }
    if (shipping) {
      this.shippingAddress = {
        address: "",
        address1: "",
        city: "",
        state: "",
        zipCode: "",
        isEditable,
      };
    }
  }
  isCheckoutDisabled(){
    return this.isShippingChargeLoading || this.isTaxLoadeding; 
  }
  checkout() {
    if(this.form.valid){      
    this.validateAddress(); 
    if (this.checkoutObj) {
      delete this.checkoutObj.shippingBoxes;
      if (!this.validateCustomerInfo()) {
        return false
      } else if (
        !this.billingAddress.address ||
        this.billingAddress.address.length < 1
      ) {
        this.commonService.openErrorSnackBar(
          "Please enter billing address",
          ""
        );
      } else if (
        !this.shippingAddress.address ||
        this.shippingAddress.address.length < 1
      ) {
        this.commonService.openErrorSnackBar(
          "Please enter shipping address",
          ""
        );
      } else if (!this.selectedShippingMethod || this.defaultSelection=='') {
        this.commonService.openErrorSnackBar(
          "Please select a shipping method",
          ""
        );
      } else if (!this.shippingAddress.validAddressIndicator) {
        this.commonService.openErrorSnackBar("Invalid shipping address", "");
      } else if (!this.billingAddress.validAddressIndicator) {
        this.commonService.openErrorSnackBar("Invalid billing address", "");
      }else if (!this.form.valid) {
        this.commonService.openErrorSnackBar("Please fill mandatory fields !", "");
      } else {
        switch (this.paymentMethod) {
          case 3:
            this.commonService.toggleLoading(true)
            $('#payment-request-button').click();
            this.disableButtons()
            // $('#cardForm').submit()
            break;
          case 1:
            this.payByInvoice();
            break;
          case 2:
            this.payByPo();
            break;
          default:
            this.commonService.openWarningSnackBar(
              "Select valid payment method",
              ""
            );
            break;
        }
      }
      this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
        if (result.matches) {
          const element = document.getElementById('my-element');
          if (element) {
           element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } 
        }  
      }); 
    } else {
      this.commonService.openErrorSnackBar("Please add items to cart", "");
    }
    return false;}
    else{
      this.commonService.openErrorSnackBar("Please fill mandatory fields !", ""); 
      for (const controlName in this.form.controls) {
        if (this.form.controls.hasOwnProperty(controlName)) {
          this.form.controls[controlName].markAsTouched();
        }
      }
    }
  }

  validateCustomerInfo(showMessage = true) {
    let errMsg = ""
    if (!this.user) return
    if (!this.user.phoneNumber) {
      errMsg = "Please enter a valid phone number"
      this.customerInfoValidity.phone = false
      this.customerInfoValidity.all = false
    } else {
      this.customerInfoValidity.phone = true
    }
    if (!this.user.firstName) {
      errMsg = "Please enter a valid customer name"
      this.customerInfoValidity.name = false
      this.customerInfoValidity.all = false
    } else {
      this.customerInfoValidity.name = true
    }
    if (errMsg) {
      if (showMessage)
        this.commonService.openErrorSnackBar(errMsg, "")
      return false
    } else {
      this.customerInfoValidity.all = true
      return true
    }
  }

  validateAll() {
    if (!this.billingAddress.address)
      this.invalidBillingAddress = true
    else
      this.invalidBillingAddress = false

    if (!this.shippingAddress.address)
      this.invalidShippingAddress = true
    else
      this.invalidShippingAddress = false

    if (!this.selectShippingMethod)
      this.invalidShippingMethod = true
    else
      this.invalidShippingMethod = false

    this.validateCustomerInfo(false)
  }

  payByCreditCard(cardInfo) {

    let formData = new FormData();
    this.checkoutObj["billingAddress"] = this.billingAddress;
    this.checkoutObj["shippingAddress"] = this.shippingAddress;
    this.checkoutObj["cardInfo"] = cardInfo;
    this.checkoutObj["taxAmount"] = this.taxObject.totalTax;
    this.checkoutObj["taxSummary"] = this.taxObject.summary;

    if(this.offerApplied){
      this.checkoutObj.cartItemsInfo.forEach(x=>{
      if(this.isStrikethrough(x) ){
        x.totalPrice=this.getDiscountedPrice(x) 
      }
      x.cartItemGrids.forEach(y=>{
        if(this.isStrikethroughSub(y)){
          y.totalPrice=this.isStrikethroughSubForDiscountAmount(y);
        }
      })
    })}
    const orderJson = {
      amount: ((this.offerApplied ? this.subtotalPrice : this.checkoutObj.totalPrice) +
        (this.getShipAmount() || 0) +
        this.taxObject.totalTax
      ).toFixed(2),
      subTotal: (this.offerApplied ? this.subtotalPrice : this.checkoutObj.totalPrice),
      shippingAmount: this.getShipAmount(),
      shippingServiceCodeId:this.selectedShippingMethod.id,
      paymentMethodNonce: cardInfo.nonce,
      orderItem: this.checkoutObj,
      paymentType: this.paymentMethod,
      shippingMethodId: this.selectedShippingMethod.id,
      couponCode: !this.invalidCouponMessage && this.addCoupon.value ? this.addCoupon.value : null,
      CouponDiscountAmount: this.offerApplied ? this.couponDiscountValue : 0,
      OrderCustomFields:this.orderCustomFields 
    };
    formData.append("orderJson", JSON.stringify(orderJson));
    this.loading = true;
    this.commonService.toggleLoading(true)
    this.storeService.createOrder(formData).subscribe(
      (res: any) => {
        this.commonService.toggleLoading(false)
        this.loading = false;
        this.scrollToTop()
        let obj={
              title: "Order Placed",
              text: "Your payment is approved and Order is being processed",
              icon: "success",
              isEmailConfirmed:this.user.emailConfirmed,
              isUserClickedOkButton:false 
            }
        if (
          res.data.transactionStatus == 6 ||
          res.data.transactionStatus == 8
        ) {
          
          window.top.postMessage("cart count-0", "*");
          this.openResponseWindowComponent(obj)
          // if (this.user.emailConfirmed) {
          //   Swal.fire({
          //     title: "Order Placed",
          //     text: "Your payment is approved and Order is being processed",
          //     icon: "success",
          //     position: "top",
          //   }).then(() => {
          //     if (this.user.emailConfirmed)
          //       this.router.navigate(["/enduser/useraccount/orderhistory"]);
          //     else
          //       window.top.postMessage("goto home", "*")
          //   });
             
          // } else {
          //   Swal.fire({
          //     title: "Order Placed",
          //     text: "Your payment is approved and Order is being processed",
          //     icon: "success",
          //     position: "top",
          //     cancelButtonText: "OK",
          //     confirmButtonText: "Complete Registration",
          //     showCancelButton: true,
          //     preConfirm: () => {
          //       window.top.postMessage("open guest signup", "*")
          //       window.parent.postMessage("action - goto profile", "*");
          //       return false
          //     },
          //     reverseButtons: true,
          //   }).then((result) => {
          //     if (!result.isConfirmed)
          //       window.top.postMessage("goto home", "*")
          //   });
          // } 
        } 
        else
        {
          // Swal.fire({
          //   title: "Order Rejected",
          //   text: res.data.message,
          //   icon: "error",
          //   position: "top",
          // });
          let obj={
            title: "Order Rejected",
            text: res.data.message,
            icon: "error",
            //isEmailConfirmed:this.user.emailConfirmed, 
          }
          this.openResponseWindowComponent(obj)
        }
      },
      (err) => {
        this.commonService.toggleLoading(false)
        this.enableButtons()
        console.log(err);
        this.loading = false;
        this.scrollToTop()
        // Swal.fire({
        //   title: "Order Rejected",
        //   text: err.error.data.message,
        //   icon: "error",
        //   position: "top",
        // });
        let obj={
          title: "Order Rejected",
          text: err.error.data.message,
          icon: "error",
          //isEmailConfirmed:this.user.emailConfirmed, 
        }
        this.openResponseWindowComponent(obj)
      }
    );
  }
  openResponseWindowComponent(obj){
      const dialogRef = this.dialogue.open(ResponseWindowComponent, {
    // width: '15%', 
    // width:'20%',
    data: obj,
    disableClose: true,
    position:{top:'20px'}
  });

  dialogRef.afterClosed().subscribe((dialogResult) => {
    // if (this.user.emailConfirmed)
    //   this.router.navigate(["/enduser/useraccount/orderhistory"]);
    // else
    //   window.top.postMessage("goto home", "*")
  });
  }


  payByPo() {
    if (this.uploadedPO) {
      $("#submit-button").remove();
      let formData = new FormData();
      formData.append("file", this.uploadedPO);
      this.checkoutObj["billingAddress"] = this.billingAddress;
      this.checkoutObj["shippingAddress"] = this.shippingAddress;
      this.checkoutObj["taxAmount"] = this.taxObject.totalTax;
      this.checkoutObj["taxSummary"] = this.taxObject.summary;
      const orderJson = {
        amount: (
          this.checkoutObj.totalPrice +
          this.selectedShippingMethod.price +
          this.taxObject.totalTax
        ).toFixed(2),
        subTotal: this.checkoutObj.totalPrice,
        shippingAmount: this.selectedShippingMethod.price,
        shippingServiceCodeId:this.selectedShippingMethod.id,
        orderItem: this.checkoutObj,
        paymentType: this.paymentMethod,
        OrderCustomFields:this.orderCustomFields 
      };
      formData.append("orderJson", JSON.stringify(orderJson));
      this.loading = true;
      this.storeService.createOrder(formData).subscribe(
        (res: any) => {
          this.loading = false;
          if (res.body.statusCode == 200) {
            this.scrollToTop()
            Swal.fire({
              title: "Order Placed",
              text: `Your order with a Purchase Order has been received. 
                   Processing of this order is contingent upon approval from the Areswear team. 
                   Please check your email or user profile for further order status.`,
              icon: "success",
              position: "top",
            }).then(() => {
              this.router.navigate(["/enduser/useraccount/orderhistory"]);
              // window.parent.postMessage("Order placed successfully!", "*");
            });
          } else {
            this.scrollToTop()
            Swal.fire({
              title: "Order Rejected",
              text: "Your payment method is rejected. Please retry with alternate payment method",
              icon: "error",
              position: "top",
            });
          }
        },
        (err) => {
          this.loading = false;
          this.scrollToTop()
          Swal.fire({
            title: "Order Rejected",
            text: "Your payment method is rejected. Please retry with alternate payment method",
            icon: "error",
            position: "top",
          });
        }
      );
    } else {
      this.commonService.openErrorSnackBar("Upload PO to continue", "");
    }
  }

  payByInvoice() {
    // if (this.uploadedCreditApplication) {
    if (this.creditApprovalCode) {
      $("#submit-button").remove();
      let formData = new FormData();
      // formData.append('file', this.uploadedCreditApplication)
      this.checkoutObj["billingAddress"] = this.billingAddress;
      this.checkoutObj["shippingAddress"] = this.shippingAddress;
      this.checkoutObj["taxAmount"] = this.taxObject.totalTax;
      this.checkoutObj["taxSummary"] = this.taxObject.summary;
      const orderJson = {
        amount: (
          this.checkoutObj.totalPrice +
          this.selectedShippingMethod.price +
          this.taxObject.totalTax
        ).toFixed(2),
        subTotal: this.checkoutObj.totalPrice,
        shippingAmount: this.selectedShippingMethod.price,
        shippingServiceCodeId:this.selectedShippingMethod.id,
        orderItem: this.checkoutObj,
        paymentType: this.paymentMethod,
        creditApprovalCode: this.creditApprovalCode,
        OrderCustomFields:this.orderCustomFields 
      };
      formData.append("orderJson", JSON.stringify(orderJson));
      this.loading = true;
      this.storeService.createOrder(formData).subscribe(
        (res: any) => {
          this.loading = false;
          if (res.body.statusCode == 200) {
            this.scrollToTop()
            Swal.fire({
              title: "Order Placed",
              text: `Your Credit Application has been received. 
                   You will be notified once the credit is approved by our team.
                   Your order details can be found in your “Order History” online at Areswear.com`,
              icon: "success",
              position: "top",
            }).then(() => {
              this.router.navigate(["/enduser/useraccount/orderhistory"]);
              // window.parent.postMessage("Order placed successfully!", "*");
            });
          } else {
            this.scrollToTop()
            Swal.fire({
              title: "Order Rejected",
              text: "Your payment method is rejected. Please retry with alternate payment method",
              icon: "error",
              position: "top",
            });
          }
        },
        (err) => {
          this.loading = false;
          this.scrollToTop()
          Swal.fire({
            title: "Order Rejected",
            text: "Your payment method is rejected. Please retry with alternate payment method",
            icon: "error",
            position: "top",
          });
        }
      );
    } else {
      this.commonService.openWarningSnackBar(
        "Please enter the credit application code ",
        ""
      );
    }
  }

  getStates() {
    this.endUserService.getAllStates().subscribe((res) => {
      this.states = res.data;
     this.getTax()
      this.replaceStateId();
    });
  }

  replaceStateId() {
    this.billingAddress.state =
      this.getState(this.billingAddress.state).name ||
      this.billingAddress.state;
    this.shippingAddress.state =
      this.getState(this.shippingAddress.state).name ||
      this.shippingAddress.state;
    this.validateAddress();
  }

  getState(id) {
    if (this.states.length) {
      let foundState = this.states.find(
        (state) => Number(state.id) == Number(id)
      );
      if (foundState) return foundState;
      else return {};
    } else {
      return {};
    }
  }

  getServiceName(serviceId) {
    if (serviceId && this.serviceArray.length)
      return this.serviceArray.find((s) => {
        return s.id == serviceId;
      }).name;
    return "";
  }

  getAvailableStoreServices() {
    this.storeService.getAvailableStoreServices().subscribe((response) => {
      this.serviceArray = response.data;
    });
  }

  getPersonalizationPrice(id) {
    switch (id) {
      case 0:
        return 0;
      case 1:
        return 7;
      case 2:
        return 6;
      case 3:
        return 13;
      default:
        return 0;
    }
  }

  validateCardNumber() {
    this.errors.cardNumber = false
    this.cardInfo.get("cardNumber").markAllAsTouched();
    if (this.isCreditCard() && this.checkLuhn()) {
      return true;
    } else {
      return false;
    }
  }

  validateCardDetails() {
    this.cardInfo.markAllAsTouched();
    let cvvEle = document.getElementById("cvvInp") as HTMLInputElement;
    this.errors.cardCvv = false
    if (
      this.cardInfo.valid &&
      cvvEle.value.length >= 3
    ) {
      let month = this.cardInfo.get("expMonth").value;
      let year = this.cardInfo.get("expYear").value;
      let valid = true;
      if (year < new Date().getFullYear()) {
        valid = false;
        this.errors.cardExpiryYear = true
      } else if (
        year == new Date().getFullYear() &&
        Number(month) < Number(new Date().getMonth() + 1)
      ) {
        valid = false;
        this.errors.cardExpiryMonth = true
      }

      if (!valid) this.commonService.openErrorSnackBar("Card is expired", "");
      else return this.validateCardNumber();

      return valid;
    } else {
      if (this.cardInfo.get("cardNumber").invalid) {
        this.commonService.openErrorSnackBar("Invalid card number", "");
        this.errors.cardNumber = true
      } else if (
        this.cardInfo.get("cardCode").invalid ||
        cvvEle.value.length < 3
      ) {
        this.commonService.openErrorSnackBar("CVV must be at least 3 characters", "");
        this.errors.cardCvv = true
      } else if (this.cardInfo.get("nameOnCard").invalid) {
        this.commonService.openErrorSnackBar("Card holder's name is invalid", "");
        this.errors.cardName = true
      } else {
        let month = this.cardInfo.get("expMonth").value;
        let year = this.cardInfo.get("expYear").value;
        if (month == 0 || year == 0) {
          this.commonService.openErrorSnackBar("Select Expiration Date", "");
          this.errors.cardExpiryMonth = true
          this.errors.cardExpiryYear = true
        }
      }
      return false;
    }
  }

  checkLuhn() {
    let cardNo = this.cardInfo.value.cardNumber;
    let nDigits = cardNo.length;
    let nSum = 0;
    let isSecond = false;
    for (let i = nDigits - 1; i >= 0; i--) {
      let d = Number(cardNo[i]);

      if (isSecond == true) d = d * 2;

      if (d > 9) d = (d % 10) + d / 10;

      nSum += d;
      isSecond = !isSecond;
    }
    if (nSum % 10 == 0) {
      return true;
    } else {
      this.commonService.openErrorSnackBar("Invalid Credit Card", "");
      this.errors.cardNumber = true
      return false;
    }
  }

  isCreditCard() {
    let cardNo = this.cardInfo.value.cardNumber;
    const regexp =
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;

    if (regexp.test(cardNo)) {
      return true;
    } else {
      this.commonService.openErrorSnackBar("Invalid Credit Card", "");
      this.errors.cardNumber = true
      return false;
    }
  }

  onCreditCardKeyDown(event) {
    let length = 15;
    const cardPrefix = this.cardInfo.value.cardNumber
      ? Number(this.cardInfo.value.cardNumber.toString().substring(0, 2))
      : null;
    if (cardPrefix) {
      loop1: for (let key in this.availableCards) {
        loop2: for (let prefix of this.availableCards[key].prefix) {
          if (this.cardInfo.value.cardNumber.toString().startsWith(prefix)) {
            length = this.availableCards[key].length;
            break loop1;
          }
        }
      }
    }
    if ([".", "+", "-", "e"].indexOf(event.key) >= 0) return false;
    else if (!Number.isNaN(Number(event.key)) && Number(event.key) <= 9)
      return event.target.value.toString().length >= length ? false : true;
    else return true;
  }

  onCVVKeyDown(event) {
    let length = 3;
    const cvv = Number(event.key);
    const cardPrefix = this.cardInfo.value.cardNumber
      ? Number(this.cardInfo.value.cardNumber.toString().substring(0, 2))
      : null;
    if (cardPrefix && this.availableCards.AE.prefix.indexOf(cardPrefix) != -1) {
      length = 4;
    }
    if (cvv && cvv <= 9)
      return event.target.value.length >= length ? false : true;
    else return true;
  }

  getBoxNames() {
    let str = "";
    this.checkoutObj.shippingBoxes.forEach((el) => {
      str += el.name + ", ";
    });
    return str.substr(0, str.length - 2);
  }

  validateAddress() {
    this.validateAll()
    if (!this.shippingAddress.address) return;
    this.shippingAddress["country"] = "US";
    this.sharedService
      .validateAddress(this.shippingAddress).subscribe((res) => {
        if (res.data.validAddressIndicator) {
          this.shippingAddress["validAddressIndicator"] = true;
          this.invalidShippingAddress = false
        } else {
          this.shippingAddress["validAddressIndicator"] = false;
          this.invalidShippingAddress = true
          // this.commonService.openErrorSnackBar("Invalid Shipping Address", "")
        }
      });

    if (!this.billingAddress.address) return;

    this.billingAddress["country"] = "US";
    this.sharedService.validateAddress(this.billingAddress).subscribe((res) => {
      if (res.data.validAddressIndicator) {
        this.billingAddress["validAddressIndicator"] = true;
      } else {
        this.billingAddress["validAddressIndicator"] = false;
        this.invalidBillingAddress = true
        // this.commonService.openErrorSnackBar("Invalid Billing Address", "")
      }
    });
  }

  backToCart() {
    this.router.navigateByUrl("/enduser/cart")
  }

  scrollToTop() {
    this.commonService.postScrollMessageToParent()
  }

  openCustomerInfoModal() {
    $('#customerInfoModal form').addClass('needs-validation')
    $('#customerInfoModal form').removeClass('was-validated')
    $('#customerInfoModal').modal("show")
  }

  getImgByCardType(cardType) {
    switch (cardType) {
      case 'Visa':
        return 'assets/images/visa.svg';
      case 'Master Card':
        return 'assets/images/mastercard.png'
      case 'American Express':
        return 'assets/images/american_express.png'
      case 'Discover':
        return 'assets/images/discover.jpg'
      default:
        return 'assets/images/credit-card.png'
    }
  }

  trimName() {
    this.updatedCustomerInformation.patchValue({
      firstName: this.updatedCustomerInformation.value.firstName.trim(),
      lastName: this.updatedCustomerInformation.value.lastName.trim(),
    })
  }
  toggleArrow(index: number) {
    this.rotateArrow[index] = !this.rotateArrow[index];
  }
  toggleAccordion(index: number) { 
    this.toggleArrow(index);
  }
}
